import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
	container: {
		width: '100%',
		marginTop: 30,
	},
	left: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& > img': {
			maxWidth: 220,
			maxHeight: 220,
		},
	},
	right: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		borderBottom: `2px solid ${theme.palette.ui.red}`,
		paddingBottom: 30,
		'& > p': {
			width: '100%',
			textAlign: 'left',
			color: theme.palette.ui.darkGray,
		},
	},
}))
