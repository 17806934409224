import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
	menuContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		boxShadow: '4px 4px 4px 4px rgba(0, 0, 255, .1);',
		// backgroundColor: '#232323',
		backgroundColor: 'white',

		'& > .header': {
			// backgroundColor: '#393536',
			backgroundColor: theme.palette.ui.red,
			padding: 10,
			paddingLeft: 15,
			'& > span': {
				cursor: 'pointer',
				display: 'flex',
				borderRadius: '50%',
				backgroundColor: 'white',
				maxWidth: 30,
				height: 30,
				justifyContent: 'center',
				alignItems: 'center',
				'& > img': {
					maxWidth: 20,
				},
			},
		},
		'& > .body': {
			padding: 20,
			'& > div': {
				padding: '15px 0',
				width: '100%',
				borderBottom: `1px solid gray`,
				fontSize: 12,
				color: theme.palette.ui.red,
				// fontWeight: 'bold',
				cursor: 'pointer',
			},
			'& > .selected': {
				// color: theme.palette.primary.main,
				color: 'gray',
				fontWeight: 'bold',
			},
		},
	},
}))
