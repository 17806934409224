import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
	menuContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'relative',
		overflowY: 'auto',
		'& > .headerContainer': {
			width: '100%',
		},
		'& > .backButtonContainer': {
			width: '87%',
			display: 'flex',
			justifyContent: 'start',
			padding: '10px 0',
			'& > div': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				boxShadow: '2px 2px 2px 2px rgba(0, 0, 255, .1);',
				borderRadius: '50%',
				padding: 5,
				cursor: 'pointer',
				'& > img': {
					maxHeight: 20,
				},
			},
		},
		'& > .bodyContainer': {
			width: '87%',
			padding: '40px 0',
			display: 'flex',
			justifyContent: 'center',
			paddingTop: 0,
			'& > .body': {
				width: '100%',
				height: '100%',
				minHeight: 600,
			},
		},
		'& > .footerContainer': {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			'& > .bodyContainer': {
				'& .restaurantsTitleContainer': {
					flexDirection: 'column !important',
					justifyContent: 'flex-start !important',
					alignItems: 'flex-start !important',
					'& > div': {
						width: '100% !important',
						justifyContent: 'flex-end',
						marginTop: 10,
						'& > .orderWarning': {
							padding: '3px 5px',
							fontSize: '10px !important',
						},
					},
				},
			},
		},
		// [theme.breakpoints.down('md')]: {
		// 	'& .bodyContainer': {
		// 		'& > .body': {
		// 			width: '50% !important',
		// 		},
		// 	},
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	'& .bodyContainer': {
		// 		'& > .body': {
		// 			width: '87% !important',
		// 		},
		// 	},
		// },
	},
}))
