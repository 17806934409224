import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Home from './home'
import { getResCategories, getRestaurants } from 'src/services/restaurant'
import {
	setIsLoading,
	setSelectedCategories,
	viewSelectedCategories,
} from '../_slice/view.slice'

const language = 'it'

const filterResByCat = (restaurants, categories) => {
	const catIds = categories.map(id => id)
	const result = restaurants.filter(restaurant => {
		const resCatIds = restaurant.categories.map(({ id }) => id)
		return resCatIds.some(resCatId => catIds.includes(resCatId))
	})
	if (categories.length > 0) return result
	else return restaurants
}

let categoriesOrder = [
	'Pizzeria',
	'Hamburger',
	'Italiano',
	'Sushi',
	'Kebab',
	'Piadine',
	'Pesce',
	'Carne',
	'Fritti',
	'Dolci',
	'Vegetariano',
]

function reorderList(list, order) {
	if (!list) return []

	const matching = []
	const remaining = []

	list.forEach(item => {
		if (order.includes(item.value)) {
			matching.push(item)
		} else {
			remaining.push(item)
		}
	})

	matching.sort((a, b) => order.indexOf(a.value) - order.indexOf(b.value))

	return [...matching, ...remaining]
}

export default ({ desktopMode }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const restaurantsContainerRef = useRef(null)

	const scrollToBodyContainer = () => {
		if (restaurantsContainerRef.current) {
			restaurantsContainerRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const [categoriesData, setCategoriesData] = useState([])
	const [restaurantsData, setRestaurantsData] = useState([])
	const [catsModalIsOpen, setCatsModalIsOpen] = useState(false)
	const [filteredRestaurants, setFilteredRestaurants] = useState([])
	const [searchText, setSearchText] = useState('')

	const selectedCategories = useSelector(viewSelectedCategories)

	const navigateNewTab = url => {
		window.open(`/#${url}`, '_blank')
	}

	const onSearchClick = () => {
		const searchResult = restaurantsData.filter(({ name }) =>
			name.toLowerCase().includes(searchText.toLowerCase())
		)
		setFilteredRestaurants(searchResult)
		scrollToBodyContainer()
	}

	const onResCardClick = id => {
		navigateNewTab(`/digital-menu/${id}`)
	}

	const onCategoryClick = cat => {
		dispatch(setSelectedCategories({ data: cat }))
		scrollToBodyContainer()
	}

	const clearSelectedCategories = () =>
		dispatch(setSelectedCategories({ action: 'clear' }))

	const onAllCatsConfirmClick = locallySelectedCats => {
		dispatch(
			setSelectedCategories({ action: 'array', data: locallySelectedCats })
		)
		scrollToBodyContainer()
	}

	const getData = () => {
		dispatch(setIsLoading(true))
		Promise.all([
			getResCategories({
				language,
				// pagination: { page: 1, elementsPerPage: 11 },
			}).then(data => data),
			getRestaurants({
				language,
				// filterCategories: selectedCategories,
			}).then(data => data),
		]).then(([categories, restaurants]) => {
			// const testRest = restaurants.map(item => {
			// 	const testDiscounts = item.discounts.map(dis => {
			// 		return { ...dis, weekday: 'MO' }
			// 	})
			// 	const { timetables } = item.options[3]
			// 	const testTime = { ...timetables[0], weekday: 'MO' }
			// 	const testOptions = item.options.map(opt => {
			// 		return { ...opt, timetables: [...opt.timetables, testTime] }
			// 	})
			// 	const testRes = {
			// 		...item,
			// 		discounts: testDiscounts,
			// 		options: testOptions,
			// 	}
			// 	return testRes
			// })

			// const manualRestaurants = [
			// 	...restaurants,
			// 	...restaurants,
			// 	...restaurants,
			// 	...restaurants,
			// 	...restaurants,
			// 	...restaurants,
			// 	...restaurants,
			// ]
			setCategoriesData(categories)
			setRestaurantsData(restaurants)
			setFilteredRestaurants(restaurants)
			dispatch(setIsLoading(false))
		})
	}

	useEffect(() => {
		getData()
	}, [])

	useEffect(() => {
		setFilteredRestaurants(filterResByCat(restaurantsData, selectedCategories))
	}, [selectedCategories, restaurantsData])

	return (
		<Home
			desktopMode={desktopMode}
			onResCardClick={onResCardClick}
			categoriesData={reorderList(categoriesData.list, categoriesOrder)}
			// categoriesData={categoriesData.list}
			restaurantsData={filteredRestaurants}
			selectedCategories={selectedCategories}
			onCategoryClick={onCategoryClick}
			clearSelectedCategories={clearSelectedCategories}
			catsModalIsOpen={catsModalIsOpen}
			setCatsModalIsOpen={setCatsModalIsOpen}
			onAllCatsConfirmClick={onAllCatsConfirmClick}
			searchText={searchText}
			setSearchText={setSearchText}
			onSearchClick={onSearchClick}
			restaurantsContainerRef={restaurantsContainerRef}
			scrollToBodyContainer={scrollToBodyContainer}
		/>
	)
}
