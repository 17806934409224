export const checkForMatches = (item, array, checkKey = 'id') => {
	const result = array.find(el => {
		return el[checkKey] === item[checkKey]
	})
	return result
}

function getItemByWeekday(date, items) {
	const weekday = date
		.toLocaleDateString('en-US', { weekday: 'short' })
		.toUpperCase()
	const item = items.find(item => item.weekday === weekday)
	return item || null
}

export const extractTimeslots = (workingHours, date = null) => {
	let item = {}
	if (date) {
		const specificDate = new Date(date)

		item = getItemByWeekday(specificDate, workingHours)
	} else {
		item = workingHours.reduce((prev, curr) => {
			const prevDuration =
				new Date(`2000-01-01T${prev.timeTo}`).getTime() -
				new Date(`2000-01-01T${prev.timeFrom}`).getTime()
			const currDuration =
				new Date(`2000-01-01T${curr.timeTo}`).getTime() -
				new Date(`2000-01-01T${curr.timeFrom}`).getTime()
			return prevDuration > currDuration ? prev : curr
		})
	}

	// Create the array with time slots
	const timeFrom = new Date(`2000-01-01T${item.timeFrom}`)
	const timeTo = new Date(`2000-01-01T${item.timeTo}`)
	const timeSlots = []

	while (timeFrom <= timeTo) {
		const hours = timeFrom.getHours()
		const minutes = timeFrom.getMinutes()
		const timeSlot = `${hours < 10 ? '0' : ''}${hours}:${
			minutes === 0 ? '00' : minutes
		}`

		timeSlots.push(timeSlot)

		// Increment the time by 30 minutes
		timeFrom.setMinutes(timeFrom.getMinutes() + 30)
	}

	return timeSlots.map((time, i) => ({ time, id: i + 1 }))
}

export const formatDate = (date, excludeYear = false) => {
	const originalDate = new Date(date)

	const day = originalDate.getDate().toString().padStart(2, '0')
	const month = (originalDate.getMonth() + 1).toString().padStart(2, '0')
	const year = originalDate.getFullYear().toString()

	return excludeYear ? `${day}/${month}` : `${day}/${month}/${year}`
}

const shuffleArray = array => {
	const shuffled = [...array]
	for (let i = shuffled.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1))
		;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
	}
	return shuffled
}

export const listModifier = (list, targetId) => {
	const foundId = list.find(id => targetId === id)
	let modifiedList = list
	if (foundId) {
		modifiedList = modifiedList.filter(id => id !== foundId)
	} else modifiedList = [...modifiedList, targetId]
	return modifiedList
}

const daysOfWeekAbbreviations = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']

const addTwoHours = timeStr => {
	// Split the time into hours and minutes
	const [hours, minutes] = timeStr.split(':').map(Number)

	// Create a new Date object starting from the beginning of the day
	const date = new Date()
	date.setHours(hours)
	date.setMinutes(minutes)
	date.setSeconds(0)

	// Add two hours
	date.setHours(date.getHours() + 2)

	// Format back to a string in HH:MM format
	const updatedHours = String(date.getHours()).padStart(2, '0')
	const updatedMinutes = String(date.getMinutes()).padStart(2, '0')

	return `${updatedHours}:${updatedMinutes}`
}

export const extractHoursByDay = (data, name, day = '') => {
	const today = new Date()
	const dayOfWeek = today.getDay()

	const todayAbbreviation = daysOfWeekAbbreviations[dayOfWeek]
	// const targetDay = day || todayAbbreviation
	const targetDay = 'SA'

	const groupedData = data?.reduce((acc, obj) => {
		const weekday = obj.weekday

		if (!acc[weekday]) {
			acc[weekday] = []
		}

		acc[weekday].push(obj)
		return acc
	}, {})

	// console.log({ name, groupedData })

	let workingHours = ''
	if (groupedData && groupedData[targetDay])
		groupedData[targetDay]?.forEach(({ timeFrom, timeTo }, i) => {
			// console.log({ i, name, timeFrom, timeTo })
			const mergedHours = `${addTwoHours(timeFrom)} - ${addTwoHours(timeTo)}`
			if (i > 0) {
				if (!workingHours.includes(mergedHours))
					workingHours += ` e ${mergedHours}`
				return
			}
			workingHours = mergedHours
		})

	return workingHours
}

export const extractDiscountByDay = (data, day = '') => {
	if (!data || !data.length) return
	const today = new Date()
	const dayOfWeek = today.getDay()

	const todayAbbreviation = daysOfWeekAbbreviations[dayOfWeek]
	const targetDay = day || todayAbbreviation

	if (!data) return ''
	const groupedData = data?.reduce((acc, obj) => {
		const weekday = obj.weekday

		if (!acc[weekday]) {
			acc[weekday] = []
		}

		acc[weekday].push(obj)
		return acc
	}, {})

	if (groupedData[targetDay]) return groupedData[targetDay][0]?.discount
	else return ''
}

export const compareObjectsByNotNullProperty = (obj1, obj2, key) => {
	const prop1NotNull = obj1[key] !== null
	const prop2NotNull = obj2[key] !== null

	if (prop1NotNull && !prop2NotNull) {
		return -1 // obj1 comes before obj2
	} else if (!prop1NotNull && prop2NotNull) {
		return 1 // obj2 comes before obj1
	} else {
		return 0 // No change in order
	}
}

// export const getRandomIndices = (array, count) => {
// 	if (!array || !array.length) return
// 	const indices = []
// 	const arrayLength = array.length

// 	if (count >= arrayLength) {
// 		return Array.from({ length: arrayLength }, (_, i) => i)
// 	}

// 	while (indices.length < count) {
// 		const randomIndex = Math.floor(Math.random() * arrayLength)

// 		if (!indices.includes(randomIndex)) {
// 			indices.push(randomIndex)
// 		}
// 	}

// 	return indices.sort((a, b) => a - b)
// }

export const getRandomIndices = (array, count, desktopMode) => {
	if (!array || !array.length || count <= 0) return []

	const arrayLength = array.length

	if (count >= arrayLength) {
		return Array.from({ length: arrayLength }, (_, i) => i)
	}

	const indices = []

	// Function to generate a random index based on conditions
	const generateRandomIndex = () => {
		let randomIndex

		let attempts = 0 // Track the number of attempts

		// Add a safety check to prevent an infinite loop
		while (attempts < 1000) {
			randomIndex = Math.floor(Math.random() * arrayLength)

			if (
				randomIndex > 3 &&
				((desktopMode && randomIndex % 3 === 0) ||
					(!desktopMode && randomIndex % 2 === 0))
			) {
				return randomIndex
			}

			attempts++
		}

		// If no valid index is found after 1000 attempts, return -1 to indicate an issue
		return -1
	}

	// Generate the first index and add it to the array
	const firstIndex = desktopMode ? generateRandomIndex() : 2

	if (firstIndex !== -1) {
		indices.push(firstIndex)
	} else {
		return [] // Return an empty array if a valid first index can't be found
	}

	// Generate the remaining indices
	while (indices.length < count - 1) {
		const randomIndex = generateRandomIndex()

		// Ensure that the generated index is unique
		if (randomIndex !== -1 && !indices.includes(randomIndex)) {
			indices.push(randomIndex)
		} else {
			break // Exit the loop if no valid index can be generated
		}
	}

	// Sort the indices in increasing order
	indices.sort((a, b) => a - b)

	return indices
}

export const isiPhone = () => {
	return /iPhone/i.test(navigator.userAgent)
}

export const isAndroid = () => {
	return /Android/i.test(navigator.userAgent)
}

export const getAppDownloadLink = () =>
	isiPhone()
		? 'https://apps.apple.com/it/app/mangiatutto-wh/id6445921906'
		: 'https://play.google.com/store/apps/details?id=com.mangiatutto&pli=1'
