import { useRef, useMemo } from 'react'
import Header from '../header'
import Footer from '../footer'
import CategoryCard from './categoryCard/categoryCard'
import ResCard from './resCard/resCard'
import AllCatsModal from './allCatsModal/allCatsModal'
import Grid from '@mui/material/Unstable_Grid2'
import {
	extractHoursByDay,
	extractDiscountByDay,
	compareObjectsByNotNullProperty,
	getRandomIndices,
	getAppDownloadLink,
} from 'src/helpers/functions'

import { useStyle } from './style'

const categories = [
	{
		image: './images/categories/avocado.png',
		title: 'avocado',
		color: '#1c9bb9',
		id: 1000,
	},
	{
		image: './images/categories/bento.png',
		title: 'bento',
		color: '#f85428',
		id: 1001,
	},
	{
		image: './images/categories/dessert.png',
		title: 'dessert',
		color: '#6ead1f',
		id: 1002,
	},
	{
		image: './images/categories/hamburger.png',
		title: 'hamburger',
		color: '#c9651a',
		id: 1003,
	},
	{
		image: './images/categories/pizza.png',
		title: 'pizza',
		color: '#2cbfa3',
		id: 1004,
	},
	{
		image: './images/categories/porridge.png',
		title: 'porridge',
		color: '#f7b239',
		id: 1005,
	},
	{
		image: './images/categories/steak.png',
		title: 'steak',
		color: '#d64394',
		id: 1006,
	},
	{ image: './images/categories/wrap.png', title: 'wrap', id: 1007 },
]

const restaurants = [
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
	},
]

const restaurants2 = [
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
		closed: true,
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
]

const restaurants3 = [
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
		closed: true,
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
	{
		resImage: './images/resPhoto.png',
		resName: 'Old Wild West',
		menuItems: 'HAMBURGER, AMERICANA',
		availability: '12:00 - 14:00 e 19:00 - 22:00',
	},
]

const colors = [
	'#1c9bb9',
	'#f85428',
	'#6ead1f',
	'#c9651a',
	'#2cbfa3',
	'#f7b239',
	'#d64394',
]

const Home = ({
	desktopMode,
	onResCardClick,
	categoriesData,
	restaurantsData,
	selectedCategories,
	onCategoryClick,
	catsModalIsOpen,
	setCatsModalIsOpen,
	clearSelectedCategories,
	onAllCatsConfirmClick,
	searchText,
	setSearchText,
	onSearchClick,
	restaurantsContainerRef,
	scrollToBodyContainer,
}) => {
	const classes = useStyle()
	const homePageContainerRef = useRef(null)

	const scrollToTop = () => {
		if (homePageContainerRef.current) {
			homePageContainerRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	// if (restaurantsData[0]?.discounts)
	// 	extractByDay(restaurantsData[0].discounts, 'TH')

	const finalRestaurants = useMemo(() => {
		const result =
			restaurantsData.length &&
			restaurantsData?.map(item => {
				const { timetables } = item?.options.find(
					({ option }) => option === 'DELIVER'
				)
				return {
					...item,
					availability: timetables?.length
						? extractHoursByDay(timetables, item.name)
						: '',
					discounts: extractDiscountByDay(item?.discounts),
				}
			})
		return (
			result &&
			result.sort((a, b) =>
				compareObjectsByNotNullProperty(a, b, 'availability')
			)
		)
	}, [restaurantsData])

	const randomIndices = getRandomIndices(finalRestaurants, 3, desktopMode)

	const firstPartRests = useMemo(() => {
		if (!finalRestaurants) return
		if (randomIndices.length < 0) return null
		return finalRestaurants.slice(0, randomIndices[0])
	}, [finalRestaurants])

	const secondPartRests = useMemo(() => {
		if (!finalRestaurants) return
		if (randomIndices.length < 1) return null
		return finalRestaurants.slice(randomIndices[0], randomIndices[1])
	}, [finalRestaurants])

	const thirdPartRests = useMemo(() => {
		if (!finalRestaurants) return
		if (randomIndices.length < 2) return null
		return finalRestaurants.slice(randomIndices[1])
	}, [finalRestaurants])

	return (
		<div className={classes.homeContainer}>
			<div className='headerContainer' ref={homePageContainerRef}>
				<Header
					desktopMode={desktopMode}
					scrollToRestaurants={scrollToBodyContainer}
				/>
			</div>
			<div
				className='bodyContainer'
				ref={desktopMode ? restaurantsContainerRef : null}
			>
				<div className='body'>
					<h3>ّFiltra i locali per tipologia</h3>
					<div className='categoriesContainer'>
						<Grid container spacing={2}>
							{/* <Grid xs={6} sm={4} md={2}>
								<CategoryCard
									image='./images/offer.png'
									title='offerte'
									color='white'
									isOffer
									hasBorder
									onClick={() => {}}
								/>
							</Grid> */}
							{categoriesData &&
								categoriesData
									?.slice(0, 11)
									.map(
										(
											{ idRestaurantCategory, value, restaurantCategory },
											i
										) => {
											// const color = getRandomColor(prevColor, colors)
											// prevColor = color
											return (
												<Grid xs={6} sm={4} md={2}>
													<CategoryCard
														image={`https://images-v2.waithero.com/${restaurantCategory.image}`}
														title={value}
														color={colors[i]}
														selected={selectedCategories.find(
															i => i === idRestaurantCategory
														)}
														onClick={() =>
															onCategoryClick(idRestaurantCategory)
														}
													/>
												</Grid>
											)
										}
									)}
							<Grid xs={6} sm={4} md={2}>
								<CategoryCard
									image='./images/eye.png'
									title='Altre'
									color='white'
									isOffer
									onClick={() => setCatsModalIsOpen(true)}
									hasShadow
								/>
							</Grid>
						</Grid>
					</div>
					<AllCatsModal
						catsModalIsOpen={catsModalIsOpen}
						setCatsModalIsOpen={setCatsModalIsOpen}
						selectedCategories={selectedCategories}
						categories={categoriesData}
						onConfirmClick={onAllCatsConfirmClick}
					/>
					<div
						className='searchContainer'
						ref={!desktopMode ? restaurantsContainerRef : null}
					>
						<input
							value={searchText}
							onChange={e => setSearchText(e.target.value)}
							placeholder='Cerca il locale'
						/>
						<div onClick={onSearchClick} className='searchButtonContainer'>
							<img alt='search' src='./images/search.png' />
						</div>
					</div>
					<div className='restaurantsTitleContainer'>
						<span className='title'>
							Cerca il locale dal quale vorresti ordinare
						</span>
						<div>
							<span className='orderWarning'>
								Non é consentito ordinare da diversi locali nello stesso ordine
							</span>
						</div>
					</div>
					{finalRestaurants.length ? (
						<div className='restaurantsContainer'>
							<Grid container spacing={4}>
								{firstPartRests &&
									firstPartRests.map(
										({
											categories,
											id,
											image,
											logo,
											name,
											discounts,
											availability,
										}) => {
											return (
												<Grid xs={12} sm={6} md={4}>
													<ResCard
														resImage={`https://images-v2.waithero.com/${image}`}
														resLogo={`https://images-v2.waithero.com/${logo}`}
														resName={name}
														menuItems={categories}
														id={id}
														onClick={() => onResCardClick(id)}
														discount={extractDiscountByDay(discounts)}
														availability={availability}
													/>
												</Grid>
											)
										}
									)}
								<Grid md={12}>
									<a
										href={getAppDownloadLink()}
										target='_blank'
										rel='noopener noreferrer'
									>
										<img
											className='banner'
											alt='banner'
											// src='./images/banner_1.png'
											src={`./images/banner_${desktopMode ? 1 : 2}.png`}
										/>
									</a>
								</Grid>
								{secondPartRests &&
									secondPartRests.map(
										({
											categories,
											id,
											image,
											logo,
											name,
											discounts,
											availability,
										}) => {
											return (
												<Grid xs={12} sm={6} md={4}>
													<ResCard
														resImage={`https://images-v2.waithero.com/${image}`}
														resLogo={`https://images-v2.waithero.com/${logo}`}
														resName={name}
														menuItems={categories}
														id={id}
														onClick={() => onResCardClick(id)}
														discount={extractDiscountByDay(discounts)}
														availability={availability}
													/>
												</Grid>
											)
										}
									)}
								<Grid xs={12} sm={6} md={4}>
									<a
										href={getAppDownloadLink()}
										target='_blank'
										rel='noopener noreferrer'
									>
										<img
											className='banner'
											alt='banner'
											src={`./images/banner_${desktopMode ? 2 : 1}.png`}
										/>
									</a>
								</Grid>
								{thirdPartRests &&
									thirdPartRests.map(
										({
											categories,
											id,
											image,
											logo,
											name,
											discounts,
											availability,
										}) => {
											return (
												<Grid xs={12} sm={6} md={4}>
													<ResCard
														resImage={`https://images-v2.waithero.com/${image}`}
														resLogo={`https://images-v2.waithero.com/${logo}`}
														resName={name}
														menuItems={categories}
														id={id}
														onClick={() => onResCardClick(id)}
														discount={extractDiscountByDay(discounts)}
														availability={availability}
													/>
												</Grid>
											)
										}
									)}
								<Grid md={12}>
									<a
										href={getAppDownloadLink()}
										target='_blank'
										rel='noopener noreferrer'
									>
										<img
											className='banner'
											alt='order-online'
											src='./images/order_online.png'
										/>
									</a>
								</Grid>
							</Grid>
						</div>
					) : (
						<div className='notFoundContainer'>
							<img alt='not-found' src='./images/not-found.png' />
							<span>NON TROVATO!</span>
						</div>
					)}
				</div>
			</div>
			<div className='footerContainer'>
				<Footer />
			</div>
			<div onClick={scrollToTop} className='goTopBottomContainer'>
				<img alt='back' src='./images/left-arrow-white.png' />
			</div>
		</div>
	)
}

export default Home
