import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
	homeContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		overflowY: 'auto',
		// zIndex: -50,

		'& > .goTopBottomContainer': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'fixed',
			boxShadow: '2px 2px 2px 2px rgba(0, 0, 255, .1);',
			borderRadius: '50%',
			marginLeft: 30,
			padding: 5,
			bottom: 20,
			// right : 30,
			cursor: 'pointer',
			zIndex: 10000,
			backgroundColor: 'white',
			'& > img': {
				maxHeight: 25,
				rotate: '90deg',
			},
		},

		'& > .headerContainer': {
			width: '100%',
		},
		'& > .bodyContainer': {
			width: '100%',
			padding: '40px 0',
			display: 'flex',
			justifyContent: 'center',

			'& > .body': {
				width: '87%',
				paddingBottom: 0,
				'& > .searchContainer': {
					display: 'flex',
					alignItems: 'center',
					height: 50,
					borderRadius: 10,
					padding: '10px 15px',
					boxShadow: '2px 2px 2px 2px rgba(0, 0, 255, .1);',
					marginTop: 30,
					'& > input': {
						flex: 1,
						border: 'none',
						height: '100%',
						color: theme.palette.ui.red,
						fontSize: 16,
						fontWeight: 'bold',
						borderRadius: 10,
						'&:focus': {
							outline: `2px solid ${theme.palette.primary.main}`,
						},
						marginRight: 10,
						padding: 5,
					},
					'& > .searchButtonContainer': {
						borderRadius: '50%',
						padding: 5,
						backgroundColor: theme.palette.primary.main,
						height: '100%',
						cursor: 'pointer',
						'& > img': {
							maxWidth: 20,
						},
					},
				},
				'& > .restaurantsTitleContainer': {
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginTop: 40,
					width: '100%',
					'& > .title': {
						fontSize: 18,
						fontWeight: 'bold',
					},
					'& > div': {
						display: 'flex',
						'& > .orderWarning': {
							color: 'white',
							padding: '3px 5px',
							borderRadius: 5,
							backgroundColor: theme.palette.ui.green,
						},
					},
				},
				'& > .restaurantsContainer': {
					marginTop: 20,
					'&  .banner': {
						objectFit: 'contain',
						maxWidth: '100%',
						minWidth: '100%',
					},
					'&  .orderOnline': {
						objectFit: 'contain',
						maxWidth: '100%',
						minWidth: '100%',
					},
				},
				'& .notFoundContainer': {
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: 10,
					'& > img': {
						maxWidth: 100,
					},
					'& > span': {
						fontSize: 16,
						fontWeight: 'bold',
						color: theme.palette.ui.red,
						paddingTop: 5,
					},
				},
			},
		},
		'& > .footerContainer': {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			'& > .bodyContainer': {
				'& .restaurantsTitleContainer': {
					flexDirection: 'column !important',
					justifyContent: 'flex-start !important',
					alignItems: 'flex-start !important',
					'& > div': {
						width: '100% !important',
						justifyContent: 'flex-end',
						marginTop: 10,
						'& > .orderWarning': {
							padding: '3px 5px',
							fontSize: '10px !important',
						},
					},
				},
			},
		},
	},
}))
