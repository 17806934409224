import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isLoading: false,
	visitedSteps: [],
	deliveryIsAdded: false,
	loginModalExpanded: false,
	menuIsOpen: false,
	selectedCategories: [],
}

const viewSlice = createSlice({
	name: 'view',
	initialState,
	reducers: {
		setIsLoading: (state, { payload }) => ({ ...state, isLoading: payload }),
		setVisitedSteps: (state, { payload }) => ({
			...state,
			visitedSteps: state.visitedSteps.includes(payload)
				? state.visitedSteps
				: [...state.visitedSteps, payload],
		}),
		setDeliveryIsAdded: (state, { payload }) => ({
			...state,
			deliveryIsAdded: payload,
		}),
		resetViewSlice: () => initialState,
		setLoginModalexpanded: (state, { payload }) => ({
			...state,
			loginModalExpanded: payload,
		}),
		setMenuIsOpen: (state, { payload }) => ({
			...state,
			menuIsOpen: !state.menuIsOpen,
		}),
		setSelectedCategories: (state, { payload: { action, data } }) => {
			if (action === 'clear') return { ...state, selectedCategories: [] }
			else if (action === 'array') return { ...state, selectedCategories: data }
			else {
				const foundItem = state.selectedCategories.find(item => data === item)
				let modifiedSectedCategories = [...state.selectedCategories]
				if (foundItem) {
					modifiedSectedCategories = modifiedSectedCategories.filter(
						item => item !== foundItem
					)
				} else modifiedSectedCategories.push(data)

				return {
					...state,
					selectedCategories: modifiedSectedCategories,
				}
			}
		},
	},
})

const { actions, reducer } = viewSlice
export default reducer

// ACTIONS
export const setIsLoading = actions.setIsLoading
export const setVisitedSteps = actions.setVisitedSteps
export const setDeliveryAdded = actions.setDeliveryIsAdded
export const resetViewSlice = actions.resetViewSlice
export const setLoginModalExpanded = actions.setLoginModalexpanded
export const setMenuIsOpen = actions.setMenuIsOpen
export const setSelectedCategories = actions.setSelectedCategories

// VIEW
export const viewIsLoading = state => state.view.isLoading
export const viewVisitedSteps = state => state.view.visitedSteps
export const viewDeliveryIsAdded = state => state.view.deliveryIsAdded
export const viewLoginModalExpanded = state => state.view.loginModalExpanded
export const viewMenuIsOpen = state => state.view.menuIsOpen
export const viewSelectedCategories = state => state.view.selectedCategories
