import { useStyle } from './style'

const ResCard = ({
	resImage,
	isClosing,
	resName,
	menuItems,
	resLogo,
	availability = false,
	onClick,
	id,
	discount,
}) => {
	const classes = useStyle({ availability })

	let firstTwoCats = ''

	for (let i = 0; i <= 1; i++) {
		const name = menuItems[i]?.value
		firstTwoCats = firstTwoCats + name + (i === 0 ? ', ' : '')
	}

	return (
		<div onClick={onClick} className={classes.resCardContainer}>
			<div className='imageContainer'>
				<img alt={resName} src={resImage} />
				{discount && (
					<span className='discountContainer'>{`-${discount}% sull'ordine`}</span>
				)}
			</div>
			<img className='logo' alt='logo' src={resLogo} />

			<div className='details'>
				<span className='resName'>{resName}</span>
				<span className='menuItems'>{firstTwoCats}</span>
				<div className='availability'>
					<div className='left'>
						<div className='circle'></div>
					</div>
					<div className='right'>
						<span>
							{!availability
								? 'Oggi non disponibile'
								: 'Oggi disponibile dalle:'}
						</span>
						{availability && (
							<span className='availablity'>{availability}</span>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ResCard
