import { useStyle } from './style'
import Grid from '@mui/material/Unstable_Grid2'

const ImagedItem = ({ image, title, texts = [] }) => {
	const classes = useStyle()

	return (
		<div className={classes.container}>
			<Grid container spacing={4}>
				<Grid xs={12} sm={3} md={3}>
					<div className={classes.left}>
						<img alt={title} src={image} />
					</div>
				</Grid>
				<Grid xs={12} sm={9} md={9}>
					<div className={classes.right}>
						{texts.map(txt => (
							<p>{txt}</p>
						))}
					</div>
				</Grid>
			</Grid>
		</div>
	)
}

export default ImagedItem
