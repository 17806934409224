import { useRef, useEffect } from 'react'

import Header from '../header'
import Footer from '../footer'
import { useStyle } from './style'

const UsageGuide = ({ desktopMode }) => {
	const classes = useStyle()

	const bodyContainerRef = useRef(null)

	const scrollToBodyContainer = () => {
		if (bodyContainerRef.current) {
			bodyContainerRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	useEffect(() => {
		// scrollToBodyContainer()
	}, [])

	return (
		<div className={classes.usageGuideContainer}>
			<div className='headerContainer'>
				<Header desktopMode={desktopMode} hasNoBody />
			</div>
			<div className='bodyContainer' ref={bodyContainerRef}>
				<div className='body'>
					<span className='spanContainer'>
						VUOI LAVORARE O COLLABORARE CON NOI?
					</span>
					<div className='item hasBorderBottom'>
						<img alt='work-with-us' src='./images/ug11.png' />
						<p>
							Siamo alla ricerca di fattorini e centralinista/gestore oridini
						</p>
						<p className='bold'>
							Sei interessato? <br /> invia un tuo contatto <br />
							Tramite email a:
							<a href='mailto:info@mangiatutto.com'> info@mangiatutto.com</a>
							<br />
							<a href='https://wa.me/3248716322'>324 8716322 (NO CHIAMATE)</a>
						</p>
					</div>
					<div className='item'>
						<img alt='work-with-us' src='./images/ug22.png' />
						<p className='bold'>
							Hai un locale e vuoi affiliarti al nostro servizio?
						</p>
						<p>
							Ricorda che con noi:
							<br />
							aumenterai la tua visibilità e amplierai la tua rete di clienti;
							<br />
							aumenterai il tuo volume di affari;
							<br />
							avrai una pagina dedicata sul nostro sito e la consulenza gratuita
							per la creazione del tuo menù digitale;
							<br />
							provvederemo noi ad inserire il tuo menù sul nostro portale;
							<br />
							avrai a disposizione il nostro staff tecnico per supporto,
							consulenza e assistenza; post dedicati alla tua attività sulle
							piattaforme social;
							<br />
						</p>
						<p className='bold'>
							Sei interessato? <br /> invia un tuo contatto <br />
							Tramite email a:
							<a href='mailto:info@mangiatutto.com'> info@mangiatutto.com</a>
							<br />
							<a href='https://wa.me/3248716322'>324 8716322 (NO CHIAMATE)</a>
						</p>
					</div>
					<img
						className='banner'
						alt='order-online'
						src='./images/order_online.png'
					/>
				</div>
			</div>
			<div className='footerContainer'>
				<Footer />
			</div>
		</div>
	)
}

export default UsageGuide
