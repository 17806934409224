import axios from 'axios'
import { reqWrapper } from 'src/helpers/queryWrapper'
import { getRequest } from 'src/setup/config/axios'

export const getResCategories = reqWrapper(body =>
	axios
		.post('/restaurant-category-language/all/lite', { ...body })
		.then(data => data)
)

export const getRestaurants = reqWrapper(body =>
	axios.post('/restaurant/mangiatutto', { ...body }).then(data => data)
)
