import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
	catsModalContaienr: {
		width: '100%',
		height: '100%',
		display: 'flex',
		maxHeight: 600,
		minHeight: 600,
		flexDirection: 'column',
		justifyContent: 'center',
		'& > .header': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			'& > span': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flex: 1,
				fontSize: 18,
				fontWeight: 'bold',
			},
			'& > img': {
				maxWidth: 18,
				cursor: 'pointer',
			},
		},
		'& > .description': {
			width: '100%',
			paddingTop: 15,
			fontSize: 13,
			textAlign: 'center',
			fontWeight: 'bold',
		},
		'& > .catsRowContainer': {
			width: '100%',
			display: 'flex',
			textAlign: 'center',
			overflowX: 'auto',
			marginTop: 20,
			paddingBottom: 3,
			paddingTop: 10,
			height: 70,
			maxHeight: 70,
			minHeight: 70,
			'& > *': {
				minWidth: 110,
				height: '100%',
				'& > img': {
					maxWidth: 25,
					minWidth: 25,
				},
				'& > span': {
					fontSize: 14,
					paddingLeft: 5,
				},
				'& > .closeButtonContainer': {
					padding: 5,
					right: -8,
					top: -8,
					'& > img': {
						maxWidth: 9,
					},
				},
			},
			'& > *:not(:first-child)': {
				marginLeft: 6,
			},
		},
		'& > .searchTitle': {
			width: '100%',
			paddingTop: 30,
			fontSize: 16,
			textAlign: 'center',
			fontWeight: 'bold',
		},
		'& > .modalSearchContainer': {
			display: 'flex',
			alignItems: 'center',
			height: 40,
			borderRadius: 10,
			padding: '10px 8px',
			boxShadow: '2px 2px 2px 2px rgba(0, 0, 255, .1);',
			marginTop: 10,
			'& > input': {
				flex: 1,
				border: 'none',
				height: '100%',
				color: theme.palette.ui.red,
				fontSize: 14,
				fontWeight: 'bold',
				borderRadius: 10,
				'&:focus': {
					outline: `2px solid ${theme.palette.primary.main}`,
				},
				marginRight: 10,
				padding: 5,
			},
			'& > .searchButtonContainer': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '50%',
				padding: '15px 7px',
				backgroundColor: theme.palette.primary.main,
				height: '100%',
				cursor: 'pointer',
				'& > img': {
					maxWidth: 18,
				},
			},
		},
		'& > .body': {
			display: 'flex',
			flexDirection: 'column',
			overflowY: 'auto',
			marginTop: 15,
			flex: 1,
			'& > .catItemContainer': {
				display: 'flex',
				alignItems: 'center',
				paddingRight: 10,

				'& > span': {
					fontSize: 14,
					fontWeight: 'bold',
				},
				'& > .circleButton': {
					border: '2px solid black',
					borderRadius: '50%',
					content: null,
					width: 25,
					height: 25,
					cursor: 'pointer',
				},
				'& > .name': {
					flex: 1,
					paddingLeft: 10,
				},
				'& > .number': {},
				'& > .selected': {
					backgroundColor: theme.palette.primary.main,
				},
			},
			'& > *:not(:first-child)': {
				marginTop: 13,
			},
		},
		'& > .buttonsContainer': {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'end',
			paddingTop: 12,

			'& > span': {
				fontSize: 14,
				fontWeight: 'bold',
				borderBottom: '1px solid black',
				cursor: 'pointer',
				height: 20,
			},
			'& > button': {
				border: 'none',
				backgroundColor: theme.palette.ui.green,
				color: 'white',
				fontSize: 14,
				fontWeight: 'bold',
				padding: '5px 10px',
				borderRadius: 7,
				cursor: 'pointer',
			},
		},
	},
	[theme.breakpoints.down('sm')]: {
		catsModalContaienr: {
			maxHeight: 400,
			minHeight: 400,
		},
	},
	[theme.breakpoints.down('md')]: {
		catsModalContaienr: {
			maxHeight: 550,
			minHeight: 550,
		},
	},
}))
