import { useState } from 'react'
import { useStyle } from './style'

import { ImageColor } from 'src/components'

const CategoryCard = ({
	image,
	title,
	color,
	isOffer,
	selected,
	onClick,
	hasBorder,
	hasShadow,
}) => {
	const [pixelColor, setPixelColor] = useState(null)
	const classes = useStyle({
		color: color || pixelColor,
		isOffer,
		hasBorder: selected,
		hasShadow,
		selected,
	})

	return (
		<div className={`${classes.categoryCardContainer}`} onClick={onClick}>
			<img
				alt={title}
				src={image}
				// pixelColor={pixelColor}
				// setPixelColor={setPixelColor}
			/>
			<span>{title}</span>
			{selected && (
				<div className='closeButtonContainer'>
					<img alt='remove' src='./images/close.png' />
				</div>
			)}
		</div>
	)
}

export default CategoryCard
