const uiColors = Object.freeze({
	red: '#db141a',
	gray: '#ccc',
	green: '#056F35',
	darkGreen: '#177135',
	yellow: '#ffb000',
	keyBlue: '#5adbdf',
	darkBlue: '#0a2057',
	darkGray: '#707070',
	veryDarkGray: '#323232',
	lightGray: '#8e8e8e',
	blue: '#1976d2',
	secondaryBlue: '#4fc4f4',
	white: '#fff',
	black: '#000',
	transparent: 'transparent',
})

const palette = {
	primary: { main: '#ad1116' },
	secondary: { main: '#f4e161' },
	ui: uiColors,
}
export default palette
