import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
	footerContainer: {
		width: '100%',
		backgroundColor: 'black',
		padding: 20,
		paddingTop: 35,
		paddingBottom: 5,
		'& .infoContainers': {
			width: '100%',
			display: 'flex',
			borderRadius: 5,
			overflow: 'hidden',
			'&  .lunchInfoContainer': {
				display: 'flex',
				width: '100%',

				backgroundColor: theme.palette.ui.darkGreen,
				padding: 10,
				'& > .left': {
					'& > button': {
						backgroundColor: theme.palette.ui.darkGreen,
						color: 'white',
						border: '1px solid white',
						padding: '5px 15px',
						borderRadius: 5,
						fontWeight: 'bold',
					},
				},
				'& > .right': {
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					paddingLeft: 15,
					'& > .timeContainer': {
						display: 'flex',
						alignItems: 'center',

						'& > .days': { fontSize: 14, color: 'white', minWidth: 80 },
						'& > .hours': { fontSize: 14, color: 'white' },
					},
					'& > .daysText': {
						fontSize: 16,
						color: 'white',
					},
					'& > .text': { fontSize: 14, color: 'white' },
				},
			},
			'&  .hasLeftBorder': {
				borderLeft: '1px solid black',
			},
		},

		'& .orderByPhoneATag': {
			textDecoration: 'none',
		},

		'&  .orderByPhone': {
			width: '100%',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			backgroundColor: theme.palette.ui.red,
			borderRadius: 5,
			padding: '5px 10px',
			cursor: 'pointer',
			'& > img': {
				maxWidth: 25,
				marginRight: 10,
			},
			'& > .right': {
				display: 'flex',
				flexDirection: 'column',
				paddingLeft: 10,
				flex: 1,
				'& > span': { color: 'white' },
				'& > .order': {
					color: 'white',
					fontSize: theme.spacing(1.6),
				},
				'& > .number': { fontSize: 18, fontWeight: 'bold' },
			},
		},

		'& .logo': {
			maxWidth: 150,
			opacity: 0.5,
		},

		'& .item': {
			display: 'flex',
			flexDirection: 'column',
			opacity: 0.5,
			height: '100%',
			'& > span': {
				textDecoration: 'underline',
				cursor: 'pointer',
				color: 'white',
			},
			'& > h3': {
				color: 'white',
			},
			'&  img': {
				maxWidth: 120,
				cursor: 'pointer',
			},
			'& > .socialMediaIcons': {
				display: 'flex',
				'&  img': {
					maxWidth: 70,
					cursor: 'pointer',
				},
				'&  .hasMarginLeft': {
					marginLeft: 10,
				},
			},
		},

		'& p': {
			paddingTop: 10,
			color: 'white',
			opacity: 0.4,
			fontSize: 12,
			'& > a': {
				color: 'white',
			},
		},
		'& .noPaddingTop': {
			paddingTop: 0,
		},

		[theme.breakpoints.down('xs')]: {
			'& .infoContainers': {
				'& .lunchInfoContainer': {
					flexDirection: 'column-reverse !important',
					'& .right': {
						paddingLeft: '0 !important',
					},
					'& button': {
						width: '100%',
						marginTop: 10,
						paddingTop: '2px !important',
						paddingBottom: '2px !important',
					},
					'& .days': { minWidth: '60px !important' },

					'& span': {
						fontSize: '11px !important',
					},
					'& div': {
						fontSize: '11px !important',
					},
				},
			},
		},
	},
}))
