import { useRef, useEffect } from 'react'
import Header from '../header'
import Footer from '../footer'
import { ImagedItem } from 'src/components'
import { useStyle } from './style'

const data = [
	{
		image: './images/cs1.png',
		texts: [
			`Appena hai ricevuto il tuo ordine verifica velocemente che sia conforme a quanto hai ordinato.
			Non trattenere il fattorino, lui non può aiutarti, se riscontrerai un problema potrai contattare il nostro servizio clienti.
			Mi raccomando effettua la verifica nel piu' breve tempo possibile ma entro e non oltre i 10 minuti dalla consegna dell'ordine, passato questo tempo non potremmo più aiutarti. Ti ricordiamo che l'ordine deve essere sempre ritirato anche se hai riscontrato difformità, solo in questo modo possiamo risolvere la problematica, se un ordine non viene ritirato crea in ogni caso un insoluto.`,
		],
	},
	{
		image: './images/cs2.png',
		texts: [
			`Se hai riscontrato un problema ad es.: pietanza errata, pietanze mancanti, oppure non è il tuo ordine, prendi nota della problematica e se possibile effettua una o piu' foto che dimostrino il disservizio. Non consumare le pietanze se risultano errate o se l'ordine non è il tuo. Se le pietanze verranno consumate anche parzialmente non potremmo risolvere il tuo problema in alcun modo.`,
		],
	},
	{
		image: './images/cs3.png',
		texts: [
			`Chiamaci al no 0773. 1761825 entro e non oltre i 10 minuti dalla ricezione dell'ordine. Prima di contattarci prendi nota di: Numero dell'ordine presente sui contenitori/busta, nominativo fornito al call center, numero di telefono reperibile, ristorante dal quale hai ordinato.
			Verrai assistito dal nostro servizio clienti che ti aiuterà a risolvere nel più breve tempo possibile la tua problematica`,
		],
	},
	{
		image: './images/cs4.png',
		texts: [
			`Per eventuali piatti mancanti o piatti errati non verranno in nessun caso effettuate riconsegne, ma verrà emesso un "buono" del valore del piatto,
			che potrai spendere al prossimo ordine in tutti i nostri locali affiliati. Per problematiche riguardanti la qualità e quantità delle pietanze, la mangiatutto non è direttamente responsabile, sarà nostra premura comunicare al locale le eventuali difformità e il locale a sua discrezione potrà risolverle emettendo un eventuale buono.
			Il buono verra' comunicato al cliente via sms entro i 2 gg lavorativi successivi alla problematica.`,
		],
	},
]

const ClientServices = ({ desktopMode }) => {
	const classes = useStyle()

	const bodyContainerRef = useRef(null)

	const scrollToBodyContainer = () => {
		if (bodyContainerRef.current) {
			bodyContainerRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	useEffect(() => {
		// scrollToBodyContainer()
	}, [])

	return (
		<div className={classes.clientServicesContainer}>
			<div className='headerContainer'>
				<Header desktopMode={desktopMode} hasNoBody />
			</div>
			<div className='bodyContainer' ref={bodyContainerRef}>
				<div className='body'>
					<span className='spanContainer'>
						PROBLEMI CON L'<span className='green'>ORDINE RICEVUTO</span>?
					</span>
					{data.map(item => (
						<ImagedItem image={item.image} texts={item.texts} />
					))}
					<img
						className='banner'
						alt='order-online'
						src='./images/order_online.png'
					/>
				</div>
			</div>
			<div className='footerContainer'>
				<Footer />
			</div>
		</div>
	)
}

export default ClientServices
