import { useRef, useEffect } from 'react'
import Header from '../header'
import Footer from '../footer'
import Grid from '@mui/material/Unstable_Grid2'
import { useStyle } from './style'

const AboutUs = ({ desktopMode }) => {
	const classes = useStyle()
	const currentYear = new Date().getFullYear()
	const targetYear = 2006
	const yearsDifference = currentYear - targetYear

	const bodyContainerRef = useRef(null)

	const scrollToBodyContainer = () => {
		if (bodyContainerRef.current) {
			bodyContainerRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	useEffect(() => {
		// scrollToBodyContainer()
	}, [])

	return (
		<div className={classes.aboutUsContainer}>
			<div className='headerContainer'>
				<Header desktopMode={desktopMode} hasNoBody />
			</div>
			<div className='bodyContainer' ref={bodyContainerRef}>
				<div className='body'>
					<h1>UNA LUNGA STORIA DI PASSIONE PER LA QUALITA'</h1>
					<img className='icon' alt='MEDAGLIA' src='./images/MEDAGLIA.png' />
					<Grid container spacing={5}>
						<Grid xs={12} sm={12} md={6}>
							<p>
								Il business nasce nel 2006 a Latina, dove viene realizzato il
								primo punto pilota dalla famiglia Rossi.
							</p>
							<p>
								Nel 2007 Graziano prende in mano le redini dell'azienda e
								nell'arco di pochi anni fa diventare "Mangiatutto" un punto di
								riferimento per chi vuole gustare al proprio domicilio, a casa o
								in ufficio le specialità dei migliori ristoranti della città.
							</p>
							<p>
								Le migliaia di famiglie che oggi "Mangiatutto" annovera tra i
								propri clienti, possono contare su di un servizio a domicilio
								efficiente e puntuale.
							</p>
							<p>
								La politica aziendale è quella di fornire un servizio di qualità
								utilizzando per il trasporto i migliori contenitori sul mercato
								al fine di non alterare le proprietà organolettiche delle
								pietanze e preservarne la temperatura.
							</p>
						</Grid>
						<Grid xs={12} sm={12} md={6}>
							<div className='evolutionContainer'>
								<h1>{yearsDifference} anni di MANGIATUTTO</h1>
								<span>il logo</span>
								<img
									className='evolution'
									alt='rider'
									src='./images/evolution.png'
								/>
							</div>
						</Grid>
					</Grid>

					<img className='banner' alt='rider' src='./images/aboutUs.png' />
					<img
						className='banner'
						alt='order-online'
						src='./images/order_online.png'
					/>
				</div>
			</div>
			<div className='footerContainer'>
				<Footer />
			</div>
		</div>
	)
}

export default AboutUs
