import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
	aboutUsContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		overflowY: 'auto',
		'& > .headerContainer': {
			width: '100%',
		},
		'& > .bodyContainer': {
			width: '100%',
			padding: '40px 0',
			display: 'flex',
			justifyContent: 'center',
			'& > .body': {
				width: '87%',
				paddingBottom: 0,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				'& > h1': {
					color: theme.palette.ui.red,
					textAlign: 'center',
					fontSize: 22,
				},
				'& > .icon': {
					objectFit: 'contain',
					maxWidth: '200px',
					minWidth: '200px',
					paddingBottom: 30,
				},
				'&  p': {
					fontSize: 18,
					color: theme.palette.ui.darkGray,
					textAlign: 'center',
				},

				'& .evolutionContainer': {
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					'& > h1': {
						width: '100%',
						fontSize: 24,
						marginBottom: 0,
						textAlign: 'center',
						fontFamily: 'Oxygen',
					},
					'& > span': { fontSize: 20, fontWeight: 'bold' },
					'& > img': {
						objectFit: 'contain',
						maxWidth: '100%',
						marginTop: 10,
						maxHeight: 290,
					},
				},
				'& > .banner': {
					objectFit: 'contain',
					maxWidth: '100%',
					minWidth: '100%',
					marginTop: 35,
				},
				// '& > .rider': {
				// 	objectFit: 'contain',
				// 	maxWidth: '100%',
				// 	minWidth: '100%',
				// 	maxHeight: 300,
				// 	marginTop: 20,
				// },
			},
		},
		'& > .footerContainer': {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			'& > .bodyContainer': {
				'& .restaurantsTitleContainer': {
					flexDirection: 'column !important',
					justifyContent: 'flex-start !important',
					alignItems: 'flex-start !important',
					'& > div': {
						width: '100% !important',
						justifyContent: 'flex-end',
						marginTop: 10,
						'& > .orderWarning': {
							padding: '3px 5px',
							fontSize: '10px !important',
						},
					},
				},
			},
		},
	},
}))
