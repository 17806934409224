import { useEffect, useState } from 'react'
import { useStyle } from './style'
import { MyModal } from 'src/components'
import { listModifier } from 'src/helpers/functions'
import CategoryCard from '../categoryCard//categoryCard'

const allCats = [
	{ id: 1001, name: 'Afgano', rank: 1 },
	{ id: 1002, name: 'Africano', rank: 1 },
	{ id: 1003, name: 'Afro poke', rank: 1 },
	{ id: 1004, name: 'Alcolici', rank: 1 },
	{ id: 1005, name: 'Ali di pollo', rank: 1 },
	{ id: 1006, name: 'Alimentari', rank: 1 },
	{ id: 1007, name: 'Americano', rank: 1 },
	{ id: 1008, name: 'Apertivo', rank: 1 },
]

const colors = [
	'#1c9bb9',
	'#f85428',
	'#6ead1f',
	'#c9651a',
	'#2cbfa3',
	'#f7b239',
	'#d64394',
]

const AllCatsModal = ({
	catsModalIsOpen,
	setCatsModalIsOpen,
	selectedCategories,
	categories = [],
	onConfirmClick,
}) => {
	const classes = useStyle()

	const [localSelectedCats, setLocalSelectedCats] = useState([])
	const [filteredCats, setFilteredCats] = useState([])
	const [searchText, setSearchText] = useState('')

	const importantCats = categories.slice(0, 11)
	const bodyCats = categories.slice(11)

	useEffect(() => {
		setLocalSelectedCats(selectedCategories)
	}, [selectedCategories])

	useEffect(() => {
		const searchResult = bodyCats.filter(({ value }) =>
			value.toLowerCase().includes(searchText.toLowerCase())
		)
		setFilteredCats(searchResult)
	}, [searchText, catsModalIsOpen])

	const onCatClick = id => {
		const newSelectedCats = listModifier(localSelectedCats, id)
		setLocalSelectedCats(newSelectedCats)
	}

	return (
		<MyModal
			handleClose={() => setCatsModalIsOpen(false)}
			open={catsModalIsOpen}
			maxWidth={400}
			borderRadius={'15px'}
		>
			<div className={classes.catsModalContaienr}>
				<div className='header'>
					<span>Cucine</span>
					<img
						alt='close'
						src='./images/close_primary.png'
						onClick={() => setCatsModalIsOpen(false)}
					/>
				</div>
				<span className='description'>
					Seleziona tutte le cucine di cui vuoi cercare i ristoranti
				</span>
				{categories.length && (
					<div className='catsRowContainer'>
						{importantCats.map(
							({ idRestaurantCategory, value, restaurantCategory }, i) => (
								<CategoryCard
									image={`https://images-v2.waithero.com/${restaurantCategory.image}`}
									title={value}
									color={colors[i]}
									selected={localSelectedCats.find(
										i => i === idRestaurantCategory
									)}
									onClick={() => onCatClick(idRestaurantCategory)}
								/>
							)
						)}
					</div>
				)}
				<span className='searchTitle'>{'Tutte le altre (A-Z)'}</span>
				<div className='modalSearchContainer'>
					<input
						placeholder='Cerca'
						value={searchText}
						onChange={e => setSearchText(e.target.value)}
					/>
					{/* <div className='searchButtonContainer'>
						<img alt='search' src='./images/search.png' />
					</div> */}
				</div>

				<div className='body'>
					{filteredCats.map(({ idRestaurantCategory, value, rank = 1 }) => (
						<div className='catItemContainer'>
							<span
								className={`circleButton ${
									localSelectedCats.find(i => i === idRestaurantCategory)
										? 'selected'
										: ''
								}`}
								onClick={() => onCatClick(idRestaurantCategory)}
							></span>
							<span className='name'>{value}</span>
							<span className='number'>{idRestaurantCategory}</span>
						</div>
					))}
				</div>
				<div className='buttonsContainer'>
					<span
						onClick={() => {
							setLocalSelectedCats([])
							setSearchText('')
						}}
					>
						Pulisci ricerca
					</span>
					<button
						onClick={() => {
							setCatsModalIsOpen(false)
							onConfirmClick(localSelectedCats)
						}}
					>
						Conferma
					</button>
				</div>
			</div>
		</MyModal>
	)
}

export default AllCatsModal
