import { useRef, useEffect } from 'react'
import Header from '../header'
import Footer from '../footer'
import Grid from '@mui/material/Unstable_Grid2'
import { ImagedItem } from 'src/components'
import { useStyle } from './style'

const data = [
	{
		image: './images/ug1.png',
		texts: [
			`Scegli il locale e le pietanze che vuoi ordinare tramite la nostra APP o la lista che trovi in Home Page.
			Mangiatutto consegna a Latina e dintorni compresi alcuni borghi, per le specifiche scarica l'APP.
			Fai attenzione, per essere evaso l'ordine deve raggiungere un importo minimo a seconda della zona di consegna, per le specifiche scarica l' APP io chiama il nostro Call center al 0773.1761825`,
		],
	},
	{
		image: './images/ug2.png',
		texts: [
			`Puoi ordinare tramite Call center o tramite APP disponibile gratuitamente per dispositivi Android o IOS`,
			`L'ordine una volta effettuato non può essere nè annullato né modificato. Ricordati di tenere sempre il telefono a portata di mano e raggiungibile fino a consegna avvenuta, i nostri fattorini potrebbero avere necessità di contattarti per velocizzare la consegna.
			Se desideri fare aggiunte ad un ordine già effettuato potrai farle effettuando nuovamente un nuovo ordine ma non garantiamo la consegna allo stesso orario dell'ordine precedente.`,
			`N.B.- Un ordine effettuato con l'applicazione non può essere ne gestito ne processato dal call center ed ugualmente se un ordine viene effettuato tramite il nostro call center non potrà essere ne gestito ne processato tramite l'applicazione.`,
		],
	},
	{
		image: './images/ug3.png',
		texts: [
			`Mangiatutto passerà la tua ordinazione al locale da te scelto che la preparerà nel più breve tempo possibile.
			I tempi di preparazione delle pietanze variano da un minimo di 10 min ad un max di 60 min in base alla pietanza, alla congestione del locale e alla grandezza dell'ordine. Nei giorni di Sabato, Domenica e festivi potrebbero esserci dei ritardi.`,
		],
	},
	{
		image: './images/ug4.png',
		texts: [
			`Ad ordine pronto un nostro fattorino lo ritirerà dal locale e te lo consegnerà ancora caldo.
			Tieni il telefono raggiungibile fino a consegna avvenuta.
			Ti ricordiamo che i nostri fattorini non possono attendere una volta arrivati al tuo indirizzo, prepara anticipatamente i contanti o carte e fatti trovare pronto al ritiro.
			Il fattorino arrivato al tuo indirizzo ti contatterà e se non risponderai attenderà solo qualche minuto dopodiché continuerà il suo giro di consegne programmate.`,
			`Proveremo a riconsegnarti l'ordine quando avremmo disponibilità di tempo. Un ordine non saldato non ti viene consegnato e genererà un
			insoluto, per effettuare un altro ordine dovrai saldare l'insoluto.`,
		],
	},
	{
		image: './images/ug5.png',
		texts: [
			`Hai appena ricevuto l'ordine e hai riscontrato un problema? Non trattenere il fattorino e non chiedere a lui di risolvere il problema il suo compito è solo quello di consegnare. Ritira l'ordine anche se presenta un problema, ricorda che l'ordine va comunque sempre ritirato, un ordine non ritirato genera un insoluto di pari importo, inoltre il non ritiro non ci consentirà di risolvere il problema.`,
			`Per sapere come risolvere la problematica chiamaci al Call center al 0773.1761825 per i dettagli CLICCA QUI`,
		],
	},
	{
		image: './images/ug6.png',
		texts: [
			`La tariffa di consegna varia in base a dove ti trovi, per le specifiche scarica l' APP disponibile gratuitamente per dispositivi Android o IOS
			Il conto va saldato al fattorino alla consegna in CONTANTI, BANCOMAT e CARTA DI CREDITO (escluso circuiti American Express e Diners Club)`,
		],
	},
	{
		image: './images/ug7.png',
		texts: [
			`Mangiatutto consegna a Latina città e zone limitrofe compresi alcuni
			borghi. Per sapere se il servizio Mangiatutto arriva anche a casa tua scarica l'APP e registrati`,
		],
	},
]

const WorkGuide = ({ desktopMode }) => {
	const classes = useStyle()

	const bodyContainerRef = useRef(null)

	const scrollToBodyContainer = () => {
		if (bodyContainerRef.current) {
			bodyContainerRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	useEffect(() => {
		// scrollToBodyContainer()
	}, [])

	return (
		<div className={classes.workGuideContainer}>
			<div className='headerContainer'>
				<Header desktopMode={desktopMode} hasNoBody />
			</div>
			<div className='bodyContainer' ref={bodyContainerRef}>
				<div className='body'>
					<span className='spanContainer'>
						COME FUNZIONA IL <span className='green'>NOSTRO SERVIZIO</span>
					</span>
					{data.map(item => (
						<ImagedItem image={item.image} texts={item.texts} />
					))}
					<span className='spanContainer hasMarginTop'>
						LA <span className='green'>QUALITA'</span> DEL NOSTRO SERVIZIO CI
						RENDE <span className='green'>UNICI</span>
					</span>
					<Grid
						container
						alignItems='center'
						justifyContent='center'
						spacing={4}
					>
						{/* <Grid item xs={12} sm={6} md={6}>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<img alt='work-guide' src='./images/workGuide1.png' />
							</div>
						</Grid> */}
						<Grid item xs={12} sm={6} md={4}>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<img
									className='descImage'
									alt='work-guide'
									src='./images/workGuide2.png'
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<img
									className='descImage'
									alt='work-guide'
									src='./images/workGuide3.png'
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<img
									className='descImage'
									alt='work-guide'
									src='./images/workGuide4.png'
								/>
							</div>
						</Grid>
					</Grid>
					<img
						className='banner'
						alt='order-online'
						src='./images/order_online.png'
					/>
				</div>
			</div>
			<div className='footerContainer'>
				<Footer />
			</div>
		</div>
	)
}

export default WorkGuide
