import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { viewIsLoading, viewMenuIsOpen } from '../_slice/view.slice'
import Home from '../home'
import AboutUs from '../aboutUs'
import WorkGuide from '../workGuide'
import ClientServices from '../clientServices'
import UsageGuide from '../usageGuide'
import DigitalMenu from '../digitalMenu'
import { Loading, Menu } from 'src/components'
import { Route, Routes, HashRouter } from 'react-router-dom'
import { useStyle } from './style'

const Body = ({ desktopMode }) => {
	return (
		<Routes>
			<Route
				exact
				path='/'
				name='Home Page'
				element={<Home desktopMode={desktopMode} />}
			/>
			<Route
				exact
				path='/about-us'
				name='About us'
				element={<AboutUs desktopMode={desktopMode} />}
			/>
			<Route
				exact
				path='/work-guide'
				name='Work Guide'
				element={<WorkGuide desktopMode={desktopMode} />}
			/>
			<Route
				exact
				path='/client-services'
				name='Client Services'
				element={<ClientServices desktopMode={desktopMode} />}
			/>
			<Route
				exact
				path='/usage-guide'
				name='Usage Guide'
				element={<UsageGuide desktopMode={desktopMode} />}
			/>
			<Route
				exact
				path='/digital-menu/:resId'
				name='Menu'
				element={<DigitalMenu desktopMode={desktopMode} />}
			/>
		</Routes>
	)
}

const App = () => {
	const theme = useTheme()
	const desktopMode = useMediaQuery(theme.breakpoints.up('md'))

	const classes = useStyle({ desktopMode })
	const open = useSelector(viewIsLoading)
	const menuIsOpen = useSelector(viewMenuIsOpen)

	return (
		<div className={classes.root}>
			<Loading open={open} />
			<HashRouter>
				<div className={`profileContainer ${menuIsOpen ? 'open' : 'closed'}`}>
					<Menu />
				</div>
				<div className='mainBody'>
					<Body desktopMode={desktopMode} />
				</div>
			</HashRouter>
		</div>
	)
}

export default App
