import { setMenuIsOpen } from 'src/scenes/_slice/view.slice'
import { useNavigate, useLocation } from 'react-router-dom'
import useStyle from './style'
import { useDispatch } from 'react-redux'

const Menu = ({}) => {
	const classes = useStyle()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const navigateNewTab = url => {
		window.open(`/#${url}`, '_blank')
	}

	const onCloseMenu = () => {
		dispatch(setMenuIsOpen())
	}

	const onItemClick = path => {
		onCloseMenu()
		navigateNewTab(path)
	}

	return (
		<div className={classes.menuContainer}>
			<div className='header'>
				<span onClick={onCloseMenu}>
					<img alt='backButton' src='./images/left-arrow-white.png' />
				</span>
			</div>
			<div className='body'>
				<div
					className={pathname === '/' && 'selected'}
					onClick={() => onItemClick('/')}
				>
					HOME
				</div>
				<div
					className={pathname === '/about-us' && 'selected'}
					onClick={() => onItemClick('/about-us')}
				>
					CHI SIAMO
				</div>
				<div
					className={pathname === '/work-guide' && 'selected'}
					onClick={() => onItemClick('/work-guide')}
				>
					COME FUNZIONA
				</div>
				<div
					className={pathname === '/client-services' && 'selected'}
					onClick={() => onItemClick('/client-services')}
				>
					SERVIZIO CLIENTI
				</div>
				<div
					className={pathname === '/usage-guide' && 'selected'}
					onClick={() => onItemClick('/usage-guide')}
				>
					COLLABORA CON NOI
				</div>
			</div>
		</div>
	)
}

export default Menu
