import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
	usageGuideContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		overflowY: 'auto',
		'& > .headerContainer': {
			width: '100%',
		},
		'& > .bodyContainer': {
			width: '100%',
			padding: '40px 0',
			display: 'flex',
			justifyContent: 'center',
			'& > .body': {
				width: '87%',
				paddingBottom: 0,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				'& span': {
					fontSize: 20,
					color: theme.palette.ui.red,
					fontWeight: 'bold',
				},
				'& > .spanContainer': {
					width: '100%',
					textAlign: 'center',
				},
				'& > .item': {
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: '35px 0',
					'& > img': {
						maxWidth: 220,
						maxHeight: 220,
					},
					'& > p': {
						color: 'gray',
						textAlign: 'center',
						'& a': {
							color: 'gray',
						},
					},
					'& > .bold': {
						'& a': {
							color: theme.palette.ui.darkGray,
							// textDecoration: 'none',
						},
						fontWeight: 'bold',
					},
				},
				'& > .hasBorderBottom': {
					borderBottom: `1px solid ${theme.palette.ui.lightGray}`,
				},
				'& > .banner': {
					objectFit: 'contain',
					maxWidth: '100%',
					minWidth: '100%',
					marginTop: 35,
				},
			},
		},
		'& > .footerContainer': {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			'& > .bodyContainer': {
				'& .restaurantsTitleContainer': {
					flexDirection: 'column !important',
					justifyContent: 'flex-start !important',
					alignItems: 'flex-start !important',
					'& > div': {
						width: '100% !important',
						justifyContent: 'flex-end',
						marginTop: 10,
						'& > .orderWarning': {
							padding: '3px 5px',
							fontSize: '10px !important',
						},
					},
				},
			},
		},
	},
}))
