import Grid from '@mui/material/Unstable_Grid2'
import { useNavigate } from 'react-router-dom'
import { useStyle } from './style'

const Footer = ({}) => {
	const classes = useStyle()
	const currentYear = new Date().getFullYear()
	const navigate = useNavigate()

	const navigateNewTab = url => {
		window.open(`/#${url}`, '_blank')
	}

	return (
		<div className={classes.footerContainer}>
			<Grid container spacing={4}>
				<Grid xs={12} sm={12} md={8}>
					<div className='infoContainers'>
						<div className='lunchInfoContainer'>
							<div className='left'>
								<button>Pranzo</button>
							</div>
							<div className='right'>
								<div className='timeContainer'>
									<span className='days'>Lun - Dom</span>
									<span className='hours'>
										<b>12:00 - 14:30 *</b>
									</span>
								</div>
								<span className='text'>Chiuso nei giorni festivi</span>
							</div>
						</div>
						<div className='lunchInfoContainer hasLeftBorder'>
							<div className='left'>
								<button>Cena</button>
							</div>
							<div className='right'>
								<div className='timeContainer'>
									<div className='days'>Lun - Dom</div>
									<div className='hours'>
										<b>18:00 - 21:30*</b>
									</div>
								</div>
								<span className='text'>Orari Call Center*</span>
								{/* 
								<div className='timeContainer'>
									<div className='days'>Sabato</div>
									<div className='hours'>
										<b>11:30 - 13:30*</b>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</Grid>
				<Grid xs={12} sm={12} md={4}>
					<a className='orderByPhoneATag' href='tel:07731761825'>
						<div className='orderByPhone'>
							<img alt='phone' src='./images/telephone.png' />
							<div className='right'>
								<span className='order'>ORDINAZIONI</span>
								<span className='number'>0773. 1761825</span>
							</div>
						</div>
					</a>
				</Grid>
			</Grid>
			<p className='noPaddingTop'>
				* Gli orari del Call Center non corrispondono agli orari dei locali.
			</p>
			<Grid container spacing={2}>
				<Grid xs={6} sm={4} md={2.4}>
					<img alt='logo' className='logo' src='./images/logo.png' />
				</Grid>
				<Grid xs={6} sm={4} md={2.4}>
					<div className='item'>
						<h3 className='title'>L'azienda</h3>
						<span
							onClick={() => navigateNewTab('/about-us')}
							className='itemText'
						>
							Chi siamo
						</span>
						<span onClick={() => navigateNewTab('/usage-guide')}>
							Collabora con noi
						</span>
					</div>
				</Grid>
				<Grid xs={6} sm={4} md={2.4}>
					<div className='item'>
						<h3 className='title'>Info utili</h3>
						<span
							onClick={() => navigateNewTab('/work-guide')}
							className='itemText'
						>
							Come funziona
						</span>
						<span onClick={() => navigateNewTab('/client-services')}>
							Servizio clienti
						</span>
					</div>
				</Grid>
				<Grid xs={6} sm={4} md={2.4}>
					<div className='item'>
						<h3 className='title'>Scarica l'app</h3>
						<a
							href='https://play.google.com/store/apps/details?id=com.mangiatutto&pli=1'
							target='_blank'
							rel='noopener noreferrer'
						>
							<img alt='google-play' src='./images/googlePlay.png' />
						</a>
						<a
							href='https://apps.apple.com/it/app/mangiatutto-wh/id6445921906'
							target='_blank'
							rel='noopener noreferrer'
						>
							<img alt='app-store' src='./images/appStore.png' />
						</a>
					</div>
				</Grid>
				<Grid xs={6} sm={4} md={2.4}>
					<div className='item'>
						<h3 className='title'>Seguici sui social</h3>
						<div className='socialMediaIcons'>
							<a
								href='https://www.facebook.com/mangiatutto.latina?locale=it_IT'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img alt='facebook' src='./images/facebook.png' />
							</a>
							<a
								href='https://www.instagram.com/mangiatutto_official/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									className='hasMarginLeft'
									alt='instagram'
									src='./images/instagram.png'
								/>
							</a>
						</div>
					</div>
				</Grid>
			</Grid>
			<p>
				Copyright {currentYear} - Quick My Food srl - P.lva - 02969630595 -
				Email: info@mangiatutto.com -
				<a
					href='https://docs.google.com/document/d/19UXrMVi-aAZ5PrJqVtLK9F_bWdEfq-sS2RpygNN2Uaw/edit'
					target='_blank'
					rel='noopener noreferrer'
				>
					Privacy Policy
				</a>
				{/* -<a href='/'>Cookie Notice</a> - <a href='/'>Credits</a> */}
			</p>
		</div>
	)
}

export default Footer
