import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
	categoryCardContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		padding: '5px 7px',
		cursor: 'pointer',
		position: 'relative',
		boxShadow: '4px 4px 4px 4px rgba(0, 0, 255, .1);',

		// backgroundColor: ({ color }) => color || theme.palette.ui.red,
		backgroundColor: 'white',
		border: ({ hasBorder }) =>
			hasBorder ? `2px solid ${theme.palette.primary.main}` : 'none',
		'& > img': {
			maxWidth: 40,
			minWidth: 40,
			borderRadius: '50%',
		},
		'& > span': {
			flex: 1,
			paddingLeft: 15,
			// color: ({ isOffer }) => (isOffer ? 'black' : 'white'),
			color: 'black',
			fontSize: 14,
		},
		'& > .closeButtonContainer': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: '#4f4545',
			position: 'absolute',
			padding: 7,
			borderRadius: '50%',
			right: -7,
			top: -7,
			'& > img': {
				maxWidth: 11,
			},
		},
	},
}))
