import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
	headerContainer: {
		width: '100%',
		height: '100%',
		position: 'relative',
		paddingTop: 100,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		zIndex: 20,
		'& > .header': {
			display: 'flex',
			flexDirection: 'row-reverse',
			alignItems: 'start',
			width: '100%',
			padding: '25px 30px',
			backgroundColor: theme.palette.primary.main,
			position: 'absolute',
			top: 0,
			height: ({ hasNoBody }) => (!hasNoBody ? 500 : null),
			zIndex: -1,
			// '& > .iconContainer': {
			// 	width: '50%',
			// 	display: 'flex',
			// 	alignItems: 'center',
			// 	flexDirection: 'row-reverse',
			// 	'& > img': {
			// 		maxWidth: 35,
			// 	},
			// },
			'& > .buttonsContainer': {
				width: ({ hasNoBody }) => (hasNoBody ? '100% !important' : '86%'),
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'row-reverse',
				justifyContent: 'space-between',
				zIndex: 100,
				'& > .right': {
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'row-reverse',
					'& > a': {
						textDecoration: 'none',
					},
					'&  .orderByPhone': {
						display: 'flex',
						alignItems: 'center',
						backgroundColor: theme.palette.ui.red,
						borderRadius: 5,
						padding: '5px 10px',
						cursor: 'pointer',
						'& > img': {
							maxWidth: 25,
							marginRight: 10,
						},
						'& > .right': {
							display: 'flex',
							flexDirection: 'column',
							'& > span': { color: 'white' },
							'& > .order': {
								color: 'white',
								fontSize: theme.spacing(1.5),
							},
						},
					},
					'& > span': {
						marginRight: 20,
						color: 'white',
						whiteSpace: 'nowrap',
						fontSize: 13.5,
						cursor: 'pointer',
					},
					'& > .selected': {
						borderBottom: '1px solid white',
					},
				},
				'& > .iconPhoto': {
					maxHeight: 60,
					cursor: 'pointer',
				},
			},
			'& > .mobileModeButtonsContainer': {
				width: '100%',
				justifyContent: 'center',
				position: 'relative',
				// TODO: make it sticky
				// position: 'fixed',
				// zIndex: 1000000,
				'& > div': {
					width: '92%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					position: 'relative',
					'& > .right': {
						display: 'flex',
						alignItems: 'center',
						'& > span': {
							marginRight: 20,
							whiteSpace: 'nowrap',
							fontSize: 14,
							fontWeight: 'bold',
							cursor: 'pointer',
							color: 'white',
						},
					},
					'&  img': {
						maxHeight: 31,
						cursor: 'pointer',
					},
					'& > .iconPhoto': {
						maxHeight: 45,
						positoin: 'absolute',
						left: '50%',
						transform: 'translate(50%, 0)',
						cursor: 'pointer',
					},
				},
			},
		},
		'& > .body': {
			width: '87%',
			zIndex: 1,
			backgroundColor: theme.palette.ui.red,
			padding: '30px 50px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative',
			'& > img': {
				maxHeight: 60,
			},
			'& > h1': {
				color: 'white',
			},

			'& > span': {
				color: 'white',
			},
			'& > .storesContainer': {
				marginTop: 30,
				display: 'flex',
				justifyContent: 'center',
				'&  img': {
					maxHeight: 50,
					cursor: 'pointer',
				},
			},
			'& > .gifImage': {
				maxHeight: 300,
				transition: 'opacity 0.5s ease-in-out',
			},
			'& > hr': {
				width: '80%',
				backgroundColor: 'black',
				marginTop: 40,
			},
			'& > .cardsContainer': {
				paddingTop: 40,
				width: '80%',
				'& .card': {
					display: 'flex',
					flexDirection: 'column',
					'& > img': { maxHeight: 60, maxWidth: 60 },
					'& > p': { color: 'white', paddingTop: 5 },
				},
				'& .paymentCard': {
					'& > span': {
						color: 'white',
					},
					'& > p': { marginBottom: 0 },
				},
			},
		},
		'& > .dishOneImage': {
			position: 'absolute',
			maxHeight: 320,
			left: 0,
			top: 0,
			zIndex: 1000,
			transition: 'opacity 0.5s ease-in-out',
		},
		'& > .dishTwoImage': {
			position: 'absolute',
			right: 0,
			top: '50%',
			transform: 'translate(0, -30%)',
			maxHeight: 320,
			zIndex: 1000,
			transition: 'opacity 0.5s ease-in-out',
		},
		[theme.breakpoints.down('md')]: {
			'& > .header': {
				padding: '25px 15px !important',
				'& > .buttonsContainer': {
					width: '83% !important',
				},
			},
		},
		[theme.breakpoints.down('sm')]: {
			'& > .header': {
				padding: '25px 15px !important',
				'& > .buttonsContainer': {
					width: '90% !important',
					'&  .orderByPhone': {
						padding: '5px 7px',
						'& > img': {
							maxWidth: '22px !important',
							marginRight: 10,
						},
						'& > .right': {
							'& > .order': {
								fontSize: '12px !important',
							},
							'& > .number': {
								fontSize: '12px !important',
							},
						},
					},
					'& > span': {
						fontSize: '12px !important',
						marginRight: '15px !important',
					},
				},
			},
			'& > .body': {
				'& > .storesContainer': {
					'&  img': {
						maxHeight: '35px !important',
					},
				},
				'& > .card': {
					alignItems: 'center',
				},
				padding: '20px 25px ',
			},
		},
		// mobile mode
		[theme.breakpoints.down('xs')]: {
			paddingTop: 80,
			'& > .header': {
				height: ({ hasNoBody }) => (!hasNoBody ? 700 : null),
				padding: '25px 10px !important',
				'& > .buttonsContainer': {
					width: '100% !important',
					'&  .right': {
						display: 'flex',
						alignItems: 'center',
						'& > span': {
							marginRight: '5px !important',
							fontSize: '12px !important',
						},
					},
					'&  .orderByPhone': {
						padding: '8px !important',
						'& > img': {
							marginRight: '0 !important',
						},
						'& > .right': {
							display: 'none !important',
						},
					},
					'& > span': {
						fontSize: '10.5px !important',
						marginRight: '12px !important',
					},
				},
			},
			'& > .body': {
				'& > h1': {
					fontSize: 22,
				},
				'& > .cardsContainer': {
					paddingTop: '0 !important',
					'&  .card': {
						alignItems: 'center',
						'& > p': {
							textAlign: 'center',
						},
					},
				},
			},
		},
	},
}))
