import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useStyle } from './style.js'
import Grid from '@mui/material/Unstable_Grid2'

function getRandomImageIndex(excludedIndexs, minVar, maxVar) {
	let randomIndex
	do {
		randomIndex = Math.floor(Math.random() * (maxVar - minVar + 1)) + minVar
	} while (excludedIndexs.includes(randomIndex))

	return randomIndex
}

const Header = ({
	desktopMode,
	isMobileMode,
	onMenuClick,
	hasNoBody,
	scrollToRestaurants,
}) => {
	const classes = useStyle({ hasNoBody, isMobileMode })
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const [dishOneIndex, setDishOneIndex] = useState(1)
	const [dishTwoIndex, setdishTwoIndex] = useState(5)

	const updateDesktopImages = useCallback(() => {
		const dishOneImage = document.querySelector('.dishOneImage')
		const dishTwoImage = document.querySelector('.dishTwoImage')

		if (!dishOneImage || !dishTwoImage) return
		dishOneImage.style.opacity = 0
		dishTwoImage.style.opacity = 0

		setTimeout(() => {
			setDishOneIndex(current => (current === 4 ? 1 : current + 1))
			setdishTwoIndex(current => (current === 8 ? 5 : current + 1))
			dishOneImage.style.opacity = 1
			dishTwoImage.style.opacity = 1
		}, 500)
	}, [dishOneIndex, dishTwoIndex])

	const updateMobileImages = useCallback(() => {
		const dishOneImage = document.querySelector('.gifImage')

		if (!dishOneImage) return
		dishOneImage.style.opacity = 0

		setTimeout(() => {
			setDishOneIndex(currentIndex =>
				currentIndex === 7 ? 1 : currentIndex + 1
			)
			dishOneImage.style.opacity = 1
		}, 500)
	}, [dishOneIndex])

	const updateImagesDynamic = useCallback(() => {
		if (!desktopMode) updateMobileImages()
		else updateDesktopImages()
	}, [updateMobileImages, updateDesktopImages, desktopMode])

	const navigateNewTab = url => {
		window.open(`/#${url}`, '_blank')
	}

	useEffect(() => {
		if (!hasNoBody) {
			const interval = setInterval(updateImagesDynamic, 4000)
			return () => clearInterval(interval)
		}
	}, [desktopMode])

	// useEffect(() => {
	// 	if (!hasNoBody) {
	// 		// Clear any existing interval when setting a new one
	// 		if (intervalId) {
	// 			clearInterval(intervalId)
	// 		}

	// 		// Define a function to update images that references the latest state
	// 		const intervalFunction = desktopMode
	// 			? updateDesktopImages
	// 			: updateMobileImages

	// 		// Set the interval
	// 		const interval = setInterval(intervalFunction, 4000)
	// 		setIntervalId(interval)

	// 		// Cleanup function to clear the interval
	// 		return () => clearInterval(interval)
	// 	}
	// 	// Specify dependencies that, when changed, should re-run this effect
	// 	// Make sure to include all values from the component scope that change over time and that are used by the effect
	// }, [desktopMode, hasNoBody])

	return (
		<div className={classes.headerContainer}>
			<div className='header'>
				{/* <div className='iconContainer'>
					<img alt='mangiaTutto' src='./logo192.png' />
				</div> */}
				{!isMobileMode && (
					<div className='buttonsContainer'>
						<div className='right'>
							<a className='aTag' href='tel:07731761825'>
								<div className='orderByPhone'>
									<img alt='phone' src='./images/telephone.png' />
									<div className='right'>
										<span className='order'>ORDINAZIONI</span>
										<span className='number'>0773. 1761825</span>
									</div>
								</div>
							</a>

							<span
								className={pathname === '/usage-guide' && 'selected'}
								onClick={() => navigateNewTab('/usage-guide')}
							>
								COLLABORA CON NOI
							</span>
							<span
								className={pathname === '/client-services' && 'selected'}
								onClick={() => navigateNewTab('/client-services')}
							>
								SERVIZIO CLIENTI
							</span>
							<span
								className={pathname === '/work-guide' && 'selected'}
								onClick={() => navigateNewTab('/work-guide')}
							>
								COME FUNZIONA
							</span>
							<span
								className={pathname === '/about-us' && 'selected'}
								onClick={() => navigateNewTab('/about-us')}
							>
								CHI SIAMO
							</span>
							<span
								// className={pathname === '/' && 'selected'}
								onClick={() => scrollToRestaurants()}
							>
								LOCALI
							</span>
							<span
								className={pathname === '/' && 'selected'}
								onClick={() => navigate('/')}
							>
								HOME
							</span>
						</div>
						<img
							alt='icon'
							className='iconPhoto'
							src='./images/icon_transparent.png'
							onClick={() => navigate('/')}
						/>
					</div>
				)}
				{isMobileMode && (
					<div className='buttonsContainer mobileModeButtonsContainer'>
						<div>
							<img alt='menu' src='./images/more.png' onClick={onMenuClick} />
							<img
								alt='icon'
								className='iconPhoto'
								src='./images/icon_transparent.png'
								onClick={() => navigate('/')}
							/>
							<div className='right'>
								{!hasNoBody && (
									<span
										// className={pathname === '/' && 'selected'}
										onClick={() => scrollToRestaurants()}
									>
										LOCALI
									</span>
								)}
								<a className='aTag' href='tel:07731761825'>
									<div className='orderByPhone'>
										<img alt='phone' src='./images/telephone.png' />
										<div className='right'>
											<span className='order'>ORDINAZIONI</span>
											<span className='number'>0773. 1761825</span>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				)}
			</div>
			{!hasNoBody && (
				<div className='body'>
					<img alt='mangiatutto' src='./images/logo.png' />
					<h1>
						SCARICA ORA LA NUOVA APP, <br />
						RISPARMIA TEMPO E DENARO!
					</h1>
					<span>Ordina online e avrai costi di consegna più bassi</span>
					<div className='storesContainer'>
						<a
							href='https://play.google.com/store/apps/details?id=com.mangiatutto&pli=1'
							target='_blank'
							rel='noopener noreferrer'
						>
							<img alt='google-play' src='./images/googlePlay.png' />
						</a>
						<a
							href='https://apps.apple.com/it/app/mangiatutto-wh/id6445921906'
							target='_blank'
							rel='noopener noreferrer'
						>
							<img alt='app-store' src='./images/appStore.png' />
						</a>
					</div>
					{!desktopMode ? (
						<img
							className='gifImage'
							alt='foods'
							src={`./images/mobileDishes/${dishOneIndex}.jpg`}
						/>
					) : (
						<hr />
					)}
					<div className='cardsContainer'>
						<Grid container spacing={{ xs: 3, sm: 6, md: 10 }}>
							<Grid xs={12} sm={6} md={4}>
								<div className='card'>
									<img alt='location' src='./images/locationIcon_header.png' />
									<p>
										Controlla in App se la tua zona è servita dal servizio
										Mangiatutto
									</p>
								</div>
							</Grid>
							<Grid xs={12} sm={6} md={4}>
								<div className='card'>
									<img alt='order' src='./images/chickenIcon_header.png' />
									<p>
										Consegnamo tutte le pietanze sempre calde e fumanti
										<br />
										Offriamo sempre la massima qualità per i tuoi cari
									</p>
								</div>
							</Grid>
							<Grid xs={12} sm={6} md={4}>
								<div className='card paymentCard'>
									<img alt='payment' src='./images/cardsIcon_header.png' />
									<p>Paghi:</p>
									<span>- Con carta di credito tramite App</span>
									<span>- In contanti al momento della consegna</span>
								</div>
							</Grid>
						</Grid>
					</div>
				</div>
			)}
			{desktopMode && !hasNoBody && (
				<img
					className='dishOneImage'
					alt='dish-1'
					src={`./images/desktopDishes/${dishOneIndex}.png`}
				/>
			)}
			{desktopMode && !hasNoBody && (
				<img
					className='dishTwoImage'
					alt='dish-2'
					src={`./images/desktopDishes/${dishTwoIndex}.png`}
				/>
			)}
		</div>
	)
}

export default Header
