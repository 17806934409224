import { useDispatch } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { setMenuIsOpen } from '../_slice/view.slice'

import Header from './header'

export default ({ desktopMode, hasNoBody, scrollToRestaurants }) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const isMobileMode = useMediaQuery(theme.breakpoints.down('xs'))
	const onMenuClick = () => dispatch(setMenuIsOpen())

	return (
		<Header
			desktopMode={desktopMode}
			isMobileMode={isMobileMode}
			onMenuClick={onMenuClick}
			hasNoBody={hasNoBody}
			scrollToRestaurants={scrollToRestaurants}
		/>
	)
}
