import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(theme => ({
	resCardContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		cursor: 'pointer',
		position: 'relative',
		'& > .imageContainer': {
			width: '100%',
			position: 'relative',
			borderRadius: 10,
			minHeight: 200,
			maxHeight: 200,
			'& > img': {
				width: '100%',
				maxHeight: 200,
				objectFit: 'cover',
				borderRadius: 5,
			},
			'& > .discountContainer': {
				position: 'absolute',
				bottom: 11,
				left: 10,
				backgroundColor: theme.palette.primary.main,
				padding: 6,
				fontSize: 13,
				color: 'white',
				borderRadius: 5,
			},
		},
		'& > .logo': {
			position: 'absolute',
			maxHeight: 60,
			maxWidth: 60,
			top: 10,
			left: 10,
		},
		'& > .details': {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			padding: 7,
			'& > .resName': {
				fontSize: 16,
				fontWeight: 'bold',
			},
			'& > .menuItems': {
				fontSize: 12,
				fontWeight: 'bold',
				paddingTop: 5,
			},
			'& > .availability': {
				display: 'flex',
				alignItems: 'flex-start',
				paddingTop: 5,

				'& > .left': {
					display: 'flex',
					justifyContent: 'flex-start',
					paddingTop: 2,
					'& > .circle': {
						width: 12,
						height: 12,
						borderRadius: '50%',
						backgroundColor: ({ availability }) =>
							!availability ? 'grey' : theme.palette.ui.green,
						content: ' ',
					},
				},
				'& > .right': {
					display: 'flex',
					flexDirection: 'column',
					paddingLeft: 7,
					'& > span': {
						fontSize: 12,
						color: 'gray',
					},
				},
			},
		},
	},
}))
