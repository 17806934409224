import { useRef, useEffect } from 'react'
import Header from '../header'
import Footer from '../footer'
import { useStyle } from './style'
import { useNavigate } from 'react-router-dom'

const DigitalMenu = ({ desktopMode, resId }) => {
	const classes = useStyle()
	const navigate = useNavigate()

	const bodyContainerRef = useRef(null)

	const scrollToBodyContainer = () => {
		if (bodyContainerRef.current) {
			bodyContainerRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	useEffect(() => {
		scrollToBodyContainer()
	}, [])

	return (
		<div className={classes.menuContainer}>
			<div className='headerContainer'>
				<Header desktopMode={desktopMode} />
			</div>
			<div
				onClick={() => {
					navigate('/')
				}}
				className='backButtonContainer'
				ref={bodyContainerRef}
			>
				<div>
					<img alt='back' src='./images/left-arrow-white.png' />
				</div>
			</div>
			<div className='bodyContainer'>
				<div className='body'>
					<iframe
						title='Embedded Content'
						src={`https://menu.waithero.com/#/menu/${resId}`} // Replace with the desired URL
						// src={`https://server.waithero.com/v1-be/#/menu/${resId}`} // Replace with the desired URL
						width='100%' // Set the width as needed
						height='100%' // Set the height as needed
						frameBorder='0' // Set frame border
					></iframe>
					{/* <object
						data='https://menu.waithero.com/#/menu/4'
						width='100%'
						height='100%'
						type='text/html'
					>
						Alternative content if the browser doesn't support .
					</object> */}
				</div>
			</div>
			<div className='footerContainer'>
				<Footer />
			</div>
		</div>
	)
}

export default DigitalMenu
